import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import constants from "../utilities/constants";

const initialState = {
  resolvedConversations: null,
  resolvedConversationsLastPeriod: null,
  operatorsFeedBackThisWeek: null,
  operatorsFeedBackLastWeek: null,
  operatorsFeedBackError: null,
  operatorsFeedBackStatus: "idle",
  operatorsPerDay: [],
  operatorsPerWeek: [],
  operatorsByDate: [],
  totalConversationNumber: null,
  totalConversationNumberStatus: "idle",
  totalConversationNumberError: null,
  operators: [],
  ratings: [],
  operatorChartMonthlyData: [],
  operatorChartDailyData: [],
  operatorChartHourlyData: [],
  conversationNumber: null,
  totalMessagesPerOperatorPerDay: [],
  totalMessagesPerOperatorPerWeek: [],
  conversationNumberLastWeek: null,
  operatorChartMonthlyStatus: "idle",
  operatorChartMonthlyError: null,
  operatorChartDailyStatus: "idle",
  operatorChartDailyError: null,
  operatorChartHourlyStatus: "idle",
  operatorChartHourlyError: null,
  conversationNumberStatus: "idle",
  resolvedConversationNumberStatus: "idle",
  conversationNumberError: null,
  getRatingsStatus: "idle",
  getRatingsError: null,
  status: "idle",
  statusgetOperatorsByDateRange: "idle",
  error: null,
  selectedValue: constants.ANALYTICS_FILTERS[0],
  statusConversationsByRating: "idle",
  conversationsBySpecificRating: [],
  hasNextPageForConversationsBySpecificRating: null,
};

export const getOperatorsPerDay = createAsyncThunk(
  "statistics/getOperatorsPerDay",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `websites/${body.websiteID}/statistics/conversations/resolved-per-operator?startDate=${body.query}&endDate=${body.query}`
      );
      data = await response.data.data;
      data.resolvedPerOperator.sort((a, b) => b?.count - a?.count);
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getOperatorsChartMonthly = createAsyncThunk(
  "statistics/getOperatorsChartMonthly",
  async (body) => {
    let data;
    try {
      let monthsCopy = [];
      const response = await axios.get(
        `websites/${body.websiteID}/statistics/conversations/chart/operator/${body.query}/month?year=${body.year}`
      );
      data = await response.data.data.conversationsPerOperatorPerMonth;
      if (response.status === 200) {
        data.sort((a, b) => new Date(a._id) - new Date(b._id));

        constants.FRENCH_MONTHS_LABEL[0].map((month, i) => {
          data.map((el) => {
            if (
              month.toLowerCase() ===
                new Date(el?._id)
                  .toLocaleDateString("fr", { month: "long" })
                  .toString()
                  .toLocaleLowerCase() &&
              el?.count
            ) {
              monthsCopy[i] = el?.count;
            }
          });
        });
        return monthsCopy;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getUsersRatings = createAsyncThunk(
  "statistics/getUsersRatings",
  async (body) => {
    let data;
    try {
      let ratings = [
        { number: 1, count: 0 },
        { number: 2, count: 0 },
        { number: 3, count: 0 },
        { number: 4, count: 0 },
        { number: 5, count: 0 },
      ];
      const response = await axios.get(
        `websites/${body.websiteID}/statistics/conversations/ratings?period=${body?.period}`
      );
      data = await response.data.data;
      if (response.status === 200) {
        data.sort((a, b) => a._id - b._id);
        ratings.map((rating, i) => {
          data.map((el) => {
            if (rating.number === el?._id) {
              ratings[i].count = el?.count;
            }
          });
        });
        return ratings;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getOperatorsChartDaily = createAsyncThunk(
  "statistics/getOperatorsChartDaily",
  async (body) => {
    let data;
    try {
      let daysCopy = [];
      const response = await axios.get(
        `websites/${body.websiteID}/statistics/conversations/chart/operator/${body.id}/week?date=${body.date}`
      );
      data = await response.data.data.conversationsPerOperatorPerWeek;
      if (response.status === 200) {
        data.sort((a, b) => new Date(a._id) - new Date(b._id));
        constants.FRENCH_DAYS_LABEL[0].map((day, i) => {
          data.map((el) => {
            if (
              day.toLowerCase() ===
                new Date(el?._id)
                  .toLocaleDateString("fr", { weekday: "long" })
                  .toString()
                  .toLocaleLowerCase() &&
              el?.count
            ) {
              daysCopy[i] = el?.count;
            }
          });
        });
        return daysCopy;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getOperatorsChartHourly = createAsyncThunk(
  "statistics/getOperatorsChartHourly",
  async (body) => {
    let data;
    try {
      let hoursCopy = [];
      const response = await axios.get(
        `websites/${body.websiteID}/statistics/conversations/chart/operator/${body.id}/hour?date=${body.date}`
      );
      data = await response.data.data.conversationsPerOperatorDay;
      if (response.status === 200) {
        data.sort((a, b) => new Date(a._id) - new Date(b._id));

        constants.HOURS_LABELS[0].map((hour, i) => {
          data.map((el) => {
            if (hour.toLowerCase() === el?._id && el?.count) {
              hoursCopy[i] = el?.count;
            }
          });
        });
        return hoursCopy;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getOperatorsPerWeek = createAsyncThunk(
  "statistics/getOperatorsPerWeek",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `websites/${body.websiteID}/statistics/conversations/resolved-per-operator?startDate=${body.startDate}&endDate=${body.endDate}`
      );
      data = await response.data.data;
      data.resolvedPerOperator.sort((a, b) => b?.count - a?.count);
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getConversationsBySpecificRating = createAsyncThunk(
  "statistics/getConversationsBySpecificRating",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `websites/${body.websiteID}/conversations/ratings?rating=${body.rating}&period=${body?.period}&page=${body.page}&limit=10`
      );
      data = await response.data.data;
      if (response.status === 200) {
        const { docs, ...meta } = data;
        return { docs, hasNextPage: meta?.hasNextPage };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getOperatorsByDateRange = createAsyncThunk(
  "statistics/getOperatorsByDateRange",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `websites/${body.websiteID}/statistics/conversations/resolved-per-operator?startDate=${body.startDate}&endDate=${body.endDate}`
      );
      data = await response.data.data;
      data.resolvedPerOperator.sort((a, b) => b?.count - a?.count);
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getConversationNumber = createAsyncThunk(
  "statistics/getConversationNumber",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteID}/statistics/conversations/number-by-period?period=${body.query}`
      );
      data = response.data?.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getOperatorsFeedBack = createAsyncThunk(
  "statistics/getOperatorsFeedBack",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteID}/feedback-stats/average?period=${body.query}`
      );
      data = response?.data?.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getResolvedConversations = createAsyncThunk(
  "statistics/getResolvedConversations",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteID}/statistics/conversations/resolved-number-by-period?period=${body.type}`
      );
      data = await response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getTotalConversationNumber = createAsyncThunk(
  "statistics/getTotalConversationNumber",
  async (websiteID) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${websiteID}/statistics/conversations`
      );
      if (response.status === 200) {
        return response.data.data.totalConversationsNumber;
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    resetConversationsBySpecificRating: (state) => {
      state.conversationsBySpecificRating = [];
    },
    changeSelectedValue: (state, action) => {
      state.selectedValue = action.payload;
    },

    setOperatorsSuccess: (state, action) => {
      state.operators = action.payload;
    },
    setWeeks: (state, action) => {
      state.lastWeek = action.payload.lastWeek;
      state.thisWeek = action.payload.thisWeek;
    },
    resetTables: (state) => {
      state.operatorChartHourlyData = [];
      state.operatorChartDailyData = [];
      state.operatorChartMonthlyData = [];
    },
  },
  extraReducers: {
    [getOperatorsPerDay.pending]: (state) => {
      state.status = "loading";
    },
    [getOperatorsPerDay.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.operatorsPerDay = action.payload?.resolvedPerOperator;
      state.operators = action.payload?.resolvedPerOperator;
      state.totalMessagesPerOperatorPerDay =
        action.payload?.messagesPerOperatorPerDay;
    },
    [getOperatorsPerDay.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getOperatorsPerWeek.pending]: (state) => {
      state.status = "loading";
    },
    [getOperatorsPerWeek.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.operatorsPerWeek = action.payload?.resolvedPerOperator;
      state.operators = action.payload?.resolvedPerOperator;
      state.totalMessagesPerOperatorPerWeek =
        action.payload?.messagesPerOperatorPerWeek;
    },
    [getOperatorsPerWeek.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getOperatorsByDateRange.pending]: (state) => {
      state.statusgetOperatorsByDateRange = "loading";
    },
    [getOperatorsByDateRange.fulfilled]: (state, action) => {
      state.statusgetOperatorsByDateRange = "succeeded";
      state.operatorsByDate = action.payload?.resolvedPerOperator;
      state.operators = action.payload?.resolvedPerOperator;
    },
    [getOperatorsByDateRange.rejected]: (state, action) => {
      state.statusgetOperatorsByDateRange = "failed";
      state.error = action.payload;
    },
    [getConversationNumber.pending]: (state) => {
      state.conversationNumberStatus = "loading";
    },
    [getConversationNumber.fulfilled]: (state, action) => {
      state.conversationNumberStatus = "succeeded";
      state.conversationNumber =
        action.payload?.totalCurrentConversationsNumber;
      state.conversationNumberLastWeek =
        action.payload?.totalPreviousConversationsNumber;
    },
    [getConversationNumber.rejected]: (state, action) => {
      state.conversationNumberStatus = "failed";
      state.conversationNumberError = action.payload;
    },
    [getOperatorsFeedBack.pending]: (state) => {
      state.operatorsFeedBackStatus = "loading";
    },
    [getOperatorsFeedBack.fulfilled]: (state, action) => {
      state.operatorsFeedBackStatus = "succeeded";
      state.operatorsFeedBackThisWeek = action.payload?.currentAverageFeedback;
      state.operatorsFeedBackLastWeek = action.payload?.previousAverageFeedback;
    },
    [getOperatorsFeedBack.rejected]: (state, action) => {
      state.operatorsFeedBackStatus = "failed";
      state.operatorsFeedBackError = action.payload;
    },
    [getResolvedConversations.pending]: (state) => {
      state.resolvedConversationNumberStatus = "loading";
    },
    [getResolvedConversations.fulfilled]: (state, action) => {
      state.resolvedConversationNumberStatus = "succeeded";
      state.resolvedConversations = action.payload.currentResolvedNumber;
      state.resolvedConversationsLastPeriod =
        action.payload.previousResolvedNumber;
    },
    [getResolvedConversations.rejected]: (state, action) => {
      state.resolvedConversationNumberStatus = "failed";
      state.conversationNumberError = action.payload;
    },
    [getOperatorsChartMonthly.pending]: (state) => {
      state.operatorChartMonthlyStatus = "loading";
    },
    [getOperatorsChartMonthly.fulfilled]: (state, action) => {
      state.operatorChartMonthlyStatus = "succeeded";
      state.operatorChartMonthlyData = action.payload;
    },
    [getOperatorsChartMonthly.rejected]: (state, action) => {
      state.operatorChartMonthlyStatus = "failed";
      state.operatorChartMonthlyError = action.payload;
    },
    [getUsersRatings.pending]: (state) => {
      state.getRatingsStatus = "loading";
    },
    [getUsersRatings.fulfilled]: (state, action) => {
      state.getRatingsStatus = "succeeded";
      state.ratings = action.payload;
    },
    [getUsersRatings.rejected]: (state, action) => {
      state.getRatingsStatus = "failed";
      state.getRatingsError = action.payload;
    },
    [getOperatorsChartDaily.pending]: (state) => {
      state.operatorChartDailyStatus = "loading";
    },
    [getOperatorsChartDaily.fulfilled]: (state, action) => {
      state.operatorChartDailyStatus = "succeeded";
      state.operatorChartDailyData = action.payload;
    },
    [getOperatorsChartDaily.rejected]: (state, action) => {
      state.operatorChartDailyStatus = "failed";
      state.operatorChartDailyError = action.payload;
    },
    [getOperatorsChartHourly.pending]: (state) => {
      state.operatorChartHourlyStatus = "loading";
    },
    [getOperatorsChartHourly.fulfilled]: (state, action) => {
      state.operatorChartHourlyStatus = "succeeded";
      state.operatorChartHourlyData = action.payload;
    },
    [getOperatorsChartHourly.rejected]: (state, action) => {
      state.operatorChartHourlyStatus = "failed";
      state.operatorChartHourlyError = action.payload;
    },
    [getTotalConversationNumber.pending]: (state) => {
      state.totalConversationNumberStatus = "loading";
    },
    [getTotalConversationNumber.fulfilled]: (state, action) => {
      state.totalConversationNumberStatus = "succeeded";
      state.totalConversationNumber = action.payload;
    },
    [getTotalConversationNumber.rejected]: (state, action) => {
      state.totalConversationNumberStatus = "failed";
      state.totalConversationNumberError = action.payload;
    },
    [getConversationsBySpecificRating.pending]: (state, action) => {
      state.statusConversationsByRating =
        action.meta.arg?.page === 1 ? "loading" : "pending";
    },
    [getConversationsBySpecificRating.fulfilled]: (state, action) => {
      state.statusConversationsByRating = "succeeded";
      state.conversationsBySpecificRating =
        action.meta.arg?.page === 1
          ? action.payload?.docs
          : state.conversationsBySpecificRating?.concat(action.payload?.docs);
      state.hasNextPageForConversationsBySpecificRating =
        action.payload?.hasNextPage;
    },
    [getConversationsBySpecificRating.rejected]: (state) => {
      state.statusConversationsByRating = "failed";
    },
  },
});

export const reducer = slice.reducer;

export const {
  setOperatorsSuccess,
  setWeeks,
  resetTables,
  changeSelectedValue,
  resetConversationsBySpecificRating,
} = slice.actions;

export default slice;
export const setOperators = (operators) => async (dispatch) => {
  try {
    await dispatch(setOperatorsSuccess(operators));
  } catch (error) {
    return console.error(error.message);
  }
};
